import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { GlobalDispatch } from '@/store';
import { AudioActions, AudioSelectors, AudioType } from '@/store/Audio';
import { LuckyDrawSelectors } from '@/store/LuckyDraw';

import { Howl, Howler } from 'howler';

export default (function AudioPlayer() {
	const setting = useSelector(LuckyDrawSelectors.selectSelectedDialSetting);
	const isSound = useSelector(AudioSelectors.selectIsSound);
	const soundType = useSelector(AudioSelectors.selectAudioType);
	const soundPlaying = useSelector(AudioSelectors.selectAudioPlaying);
	const isDraw = useSelector(AudioSelectors.selectIsDraw);
	const isDrawSuccess = useSelector(AudioSelectors.selectIsDrawSuccess);

	const intervalRef = useRef<NodeJS.Timer | undefined>();
	const timeoutRef = useRef<NodeJS.Timeout | undefined>();

	const createSound = (soundType: string) => {
		return new Howl({
			src: [soundType],
			format: ['mp3'],
			loop: false, // Disable default looping
		});
	};

	const playSound = (sound: any) => {
		// Ensure any existing playback is stopped
		pauseSound(sound);
		sound.stop();

		sound.play();
		return sound;
	};

	const pauseSound = (sound: any) => {
		sound.pause();
		GlobalDispatch(AudioActions.stopAudioRequest());
	};

	// global mute audio
	useEffect(() => {
		Howler.mute(isSound);
	}, [isSound]);

	useEffect(() => {
		const eventSound = createSound(String(soundType));
		if (soundPlaying) {
			playSound(eventSound);
		}
	}, [soundPlaying, soundType]);

	useEffect(() => {
		if (isDraw && !soundPlaying && !soundType) {
			const betSound = createSound(AudioType.BET);
			playSound(betSound).once('end', () => {
				const bonusSound = createSound(AudioType.GAME_BONUS);
				playSound(bonusSound).once('end', () => {
					const spinSound = createSound(AudioType.SPIN);
					playSound(spinSound);
				});
			});

			const rollerSound = createSound(AudioType.ROLLER_LOOP);
			playSound(rollerSound);

			intervalRef.current = setInterval(() => {
				const rollerSound = createSound(AudioType.ROLLER_LOOP);
				playSound(rollerSound);
			}, 500);

			// if drawType is automation draw
			// else drawType is manual draw
			if (!setting.drawType) {
				pauseSound(rollerSound);
				timeoutRef.current = setTimeout(() => {
					GlobalDispatch(AudioActions.stopAudioRequest());
					const tadaSound = createSound(AudioType.TADA);
					playSound(tadaSound);
					tadaSound.once('end', () => {
						const winnerSound = createSound(AudioType.WINNER);
						playSound(winnerSound);
					});
				}, setting.drawDuration * 1000);
			}
		}

		if (isDrawSuccess) {
			const jackpotComingSound = createSound(AudioType.JACKPOT_COMING);
			playSound(jackpotComingSound);
			jackpotComingSound.once('end', () => {
				GlobalDispatch(AudioActions.playDrawFinally());
				const tadaSound = createSound(AudioType.TADA);
				playSound(tadaSound);
				tadaSound.once('end', () => {
					const overSound = createSound(AudioType.JACKPOT_WIN);
					playSound(overSound);
				});
			});
		}
		return () => {
			clearInterval(intervalRef.current);
			clearTimeout(timeoutRef.current);
		};
	}, [isDraw]);

	return <></>;
});
