'use client';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';

import { ServiceApi } from '@/services';
import {
	AuthActions,
	AuthSelector,
	GlobalDispatch,
	StartupActions,
} from '@/store';
import { LuckyDrawActions } from '@/store/LuckyDraw';

const WrapperContainer = ({ children }: { children: any }) => {
	const logged = useSelector(AuthSelector.selectLogged);
	const router = useRouter();

	useEffect(() => {
		GlobalDispatch(StartupActions.startup());

		const naviMonitor = (response: any) => {
			if (response?.data?.error?.statusCode === 401) {
				GlobalDispatch(AuthActions.logoutRequest({ router }));
			}
		};

		ServiceApi.api.addMonitor(naviMonitor);
	}, [router]);

	useEffect(() => {
		if (!logged) return;

		GlobalDispatch(LuckyDrawActions.getEventListRequest());
	}, [logged]);

	return children;
};

export default WrapperContainer;
