'use client';
import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';

import AudioPlayer from '@/app/_components/AudioPlayer';
import Analytic from '@/app/_components/Common/Analytic';
import WrapperContainer from '@/app/_components/WapperContainer';
import { persistor, store } from '@/store';

import { PersistGate } from 'redux-persist/integration/react';

function GlobalLayout({ children }: { children: ReactNode }) {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<WrapperContainer>{children}</WrapperContainer>
				<AudioPlayer />
				<Analytic id={'G-YJWDXM3T57'} />
			</PersistGate>
		</Provider>
	);
}

export default GlobalLayout;
